/*
 * main axis : 중심축 방향
 * cross axis : 반대축 방향
 * --------------------------------------
 * 1. Container에 포함되는 Property 들..
 * --------------------------------------
 * display: flex;
 * flex-direction: row;    중심축 main axis을 정함.
 *                         row | row-reverse | column | column-reverse
 * flex-wrap: nowrap;      Item 들이 많으면 다음깾 또는 다음 Column으로 넘어 갈 지를 정함.
 *                         nowrap | wrap | wrap-reverse
 * justify-content:   ;    Item들을 각각 어떤 간격으로 배치할 지를 정함. (main axis 에 대하여 지정)
 *                         flex-start : Item들을 붙여서 왼쪽/위쪽으로 배치.(Default)
 *                         flex-end : Item들을 붙여서 오른쪽/아래쪽으로 배치. 
 *                         center : Item들을 붙여서 가로 중앙 /세로 중앙에 배치. 
 *                         space-between : Item들을 일정한 간격으로 배치하는데, 양쪽 끝의 공간은 없음.
 *                         space-around : Item들을 일정한 간격으로 배치하는데, 양쪽 끝의 공간은 다른 것의 1/2.
 *                         space-evenly : Item들을 일정한 간격으로 배치하는데, 양쪽 끝의 공간도 동일.
 * align-items:       ;    Item들 전체를 어떻게 늘려서 배치할 지를 정함. (cross axis 에 대하여 지정)
 *                         center : Item들 전체를 반대축(cross axis) 방향에서 전체적으로 중앙에 배치.
 *                         baseline : Item들 전체를 반대축 방향에서 전체적으로 수평/수직으로 조정하여 배치.
 *                         stretch : Item들 전체를 반대축 방향에서 전체적으로 수평/수직으로 펼쳐서 배치.(default)
 *                         flex-start, flex-end, 
 * align-content:     ;    반대축(cross axis) 방향에 대하여, Item들을 각각 어떤 간격으로 배치할 지를 정함. justify-content 개념과 동일함.
 *                         normal : 그냥 그대로 (default)
 *                         flex-start | flex-end | center | space-between | space-around | space-evenly | stretch | start | end | baseline | first baseline | last baseline + ... safe | unsafe
 * --------------------------------------
 * 2. Item에 포함되는 Property 들..
 * --------------------------------------
 * order: 0;               해당 Item이 어떤 순서에 배치되어야 하는지를 정함.
 * flex-grow: 0;           해당 Item을 상대적으로 널려서 배치함. 0으로 지정하면 늘리지 않고, 여러 Item들이 모두 1 이면 모두 동일한 길이로 늘려짐. 여러 Item들중 하나만 1인 경우 그 Item만 전체적으로 늘려짐.
 * flex-shrink: 1;         해당 Item이 점점 줄어 들어 자신의 크기보다 줄어 들 때 어떻게 상재적으로 줄어 들지를 지정. 
 * flex-basis: auto;       해당 Item을 상대적으로 지정한 비율로 차지하게 함.(늘리거나 줄이거나 같은 비율로... 일반적으로 한 라인을 100 %에서 나누어 지정하는 방법을 사용)
 * flex                    The shorthand for flex-grow, flex-shrink and flex-basis combined. The default is 0 1 auto
 * align-self              해당 Item을 반대축(cross axis) 방향에 대하여, 특별나게 배치할 때 사용.
 *                         auto | flex-start | flex-end | center | baseline | stretch
 */

.gf__screenshots {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: normal;

    padding: 2rem;
    margin: 4rem;
    
    border-radius: 1rem;
    background: var(--gradient-bar);
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.gf__screenshots-heading {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.gf__screenshots-heading p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
}

.gf__screenshots-heading h1 {
    font-family: var(--font-family);
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    margin: 1rem 0;
}

.gf__screenshots-heading h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 40px;
    font-weight: 800;
}

.gf__screenshots-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: space-around;

    margin: 1rem 0 2.5rem 0;
}

@media screen and (max-width: 910px) {
    .gf__screenshots-container {
        justify-content:space-around;
    }
}

/* Low Resolution Tablets and ipads max-width */
@media screen and (max-width: 750px) {
    .gf__screenshots {
        padding: 1.5rem;
        margin: 3rem;
    }

    .gf__screenshots-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .gf__screenshots-heading h3 {
        font-size: 18px;
        line-height: 34px;
    }

    .gf__screenshots-heading p {
        font-size: 12px;
        line-height: 20px;
    }
}
/* For normal Smartphone */
@media screen and (max-width: 485px) {
    .gf__screenshots {
        padding: 0.5rem;
        margin: 3rem 0.5rem 0.5rem 0.5rem;
    }
}