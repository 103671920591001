.gf__screen {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;

    margin: 1rem;

    /* background: var(--color-screen-bg); */
}

.gf__screen__image { 
    width: 100%;
    padding: 0.1rem;
    background: var(--color-screen-bg);
}

.gf__screen__image img {
    width: 300px;
}

/* .gf__screen__image img {
    width: 100%;
} */

.gf__screen__details {
    width: 100%;
    padding: 1rem;
    background: var(--color-screen-bg);
}

/* .gf__screen__details h4 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;

    color: #fff;
} */

.gf__screen__details p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    color: #fff;
}