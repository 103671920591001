.gf__header {
    display: flex;
    flex-direction: row;
}

.gf__header-content { 
    flex: 1.3;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.gf__header-content h1 {
    font-family: var(--font-family);
    font-size: 62px;
    line-height: 75px;
    font-weight: 800;
    letter-spacing: -0.04em;
}

.gf__header-content p {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.gf__header-content__badge {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 3rem;
}

.gf__header-content__badge img {
    width: 100%;
    max-width: 284px;

    cursor: pointer;
}

.gf__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1.5rem 0 5rem 0
}

.gf__header-image img {
    width: 100%;
    max-width: 820px;
}

/* Media Query
 */
/* Screen Width Size가 더 큰 경우, 위에서 정의된 스타일 대로 표시된다. */
@media screen and (max-width: 1800px) { 
    .gf__header-content h1 {
        font-size: 56px;
        line-height: 68px;
    }
}

@media screen and (max-width: 1600px) { 
    .gf__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }
}

@media screen and (max-width: 1400px) { 
    .gf__header-content h1 {
        font-size: 40px;
        line-height: 50px;
    }
}

@media screen and (max-width: 1200px) { 
    .gf__header-content__badge img {
        max-width: 200px;
    }
}

/* Tablets Ipads portrait mode max-width */
@media screen and (max-width: 1050px) {
    .gf__header {
        flex-direction: column;
    }

    .gf__header-content {
        margin: 0 0 3rem;
    }

    .gf__header-content h1 {
        font-size: 36px;
        line-height: 40px;
    }

    .gf__header-content p {
        font-size: 22px;
        line-height: 34px;
    }
}
/* Low Resolution Tablets and ipads max-width */
@media screen and (max-width: 780px) {
    .gf__header-content__badge {
        margin-top: 1.5rem;
    }

    .gf__header-content p {
        font-size: 18px;
        line-height: 30px;
    }

    .gf__header-image {
        margin: 1.5rem 0
    }
}
/* For normal Smartphone */
@media screen and (max-width: 485px) {
    .gf__header-content h1 {
        font-size:26px;
        line-height: 32px;
    }

    .gf__header-image {
        margin: 0 0 1.5rem 0
    }
}