.gf__footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: normal;

    background: var(--color-footer);
}

.gf__footer-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: normal;
}

.gf__footer-links__items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: normal;
}

.gf__footer-links__items p {
    color: #fff;
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;

    margin: 0.5rem;
    cursor: pointer;
}

.gf__footer-links__sns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: stretch;
    align-content: normal;
}
.gf__footer-links__sns img {
    width: 40px;

    margin: 0.4rem;
    cursor: pointer
}

.gf__footer-links__logo {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: normal;

    margin-right: 2rem;
}

.gf__footer-links__logo p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;

    color: var(--color-text);
    margin: 1rem 0;
}

.gf__footer-links__logo img {
    width: 100%;
    max-width: 200px;
}

.gf__footer-copyright {
    margin-top: 2rem;
    text-align: right;
    width: 100%;
}

.gf__footer-copyright p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;

    color: var(--color-text);
}

/* Media Query
 */
/* Screen Width Size가 더 큰 경우, 위에서 정의된 스타일 대로 표시된다. */

@media screen and (max-width: 1055px) {
    .gf__footer-links__logo img {
        max-width: 180px;
    }

    .gf__footer-links__sns {
        justify-content: flex-start;
    }
}
/* Screen Width Size가 400 ~ 550 까지..  */
@media screen and (max-width: 550px) {
    .gf__footer-links div {
        margin: 1rem 0;
    }
}
