.gf__usage {
    display: flex;
    flex-direction: row;
}

.gf__usage-image { 
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    margin-right: 4rem;
}

.gf__usage-image img {
    width: 100%;
    max-width: 500px;
}

.gf__usage-content {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.gf__usage-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #ff8A71;
    margin-bottom: 2rem;
}

.gf__usage-content h1 {
    font-family: var(--font-family);
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    margin: 1rem 0;
}

.gf__usage-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;

    margin-bottom: 1.5rem;
}

.gf__usage-blog {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gf__usage-blog button {
    padding: 1rem 2rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

/* Media Query
 */
 /* Screen Width Size가 더 큰 경우, 위에서 정의된 스타일 대로 표시된다. */

/* Screen Width Size가 650 ~ 950 까지.. */
@media screen and (max-width: 950px) {
    .gf__usage {
        flex-direction: column;
    }

    .gf__usage-image {
        flex: 1;

        justify-content: center;
        align-items: center;


        margin: 1rem 0;
    }

    .gf__usage-image img {
        width: 100%;
        max-width: 300px;
    }

    /* .gf__usage-image img {
        width: unset;
        height: unset;
    } */

    .gf__usage-content {
        margin-top: 2rem;
    }
}
/* Screen Width Size가 ~ 650 까지.. */
@media screen and (max-width: 650px) {
    .gf__usage-image { 
        justify-content: center;
        align-items: center;
    
        margin-right: 0;
    }

    .gf__usage-image img {
        width: 240px;
    }

    .gf__usage-content h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .gf__usage-content p {
        font-size: 16px;
        line-height: 20px;
    }
}

/* For normal Smartphone */
@media screen and (max-width: 485px) {
    .gf__usage-content h4 {
        font-size: 18px;
        line-height: 30px;

        margin-bottom: 1rem;
    }

    .gf__usage-image {
        margin: 1rem 0 0 0;
    }

    .gf__usage-image img {
        width: 160px;
    }

    .gf__usage-blog button {
        padding: 0.6rem 1.5rem;
    }
}