/* Gradient Color.
 *  이 데이터는 https://angrytools.com/gradient/ 에서 만들어서 Copy해 온 데이터 임. 
 */
.gf__whatgf { 

    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-screen-bg);
}

.gf__whatgf-heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: normal;
    
    margin: 2rem;
}

.gf__whatgf-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    /* max-width: 510px; */
}

.gf__whatgf-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: normal;

    margin: 1rem 2rem;
}

.gf__whatgf-container-image {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-around;
    margin: 1rem 0 0 0;
}

.gf__whatgf-container-image img {
    /* flex: 1; */
    width: 650px;
    padding: 2rem;
}

/* Media Query
 */
/* Screen Width Size가 더큰 경우, 위에서 정의된 스타일 대로 표시된다. */

@media screen and (max-width: 1600px) {
    .gf__whatgf-container-image img {
        width: 520px;
        padding: 1.5rem;
    }
}

@media screen and (max-width: 1250px) {
    .gf__whatgf-container-image img {
        width: 350px;
        padding: 1rem;
    }
}
/* Tablets Ipads portrait mode max-width */
@media screen and (max-width: 1050px) { 
    .gf__whatgf { 
        padding: 1rem;
    }

    .gf__whatgf-heading h1 {
        font-size: 30px;
        line-height: 34px;
    }

    .gf__whatgf-container {
        margin: 1rem 1rem;
    }
}

/* Screen Width Size가 650 ~ 850 까지.. */
@media screen and (max-width: 850px) {
    .gf__whatgf-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    /* .gf__whatgf-heading p {
        margin-top: 1rem;
    } */
}
/* Low Resolution Tablets and ipads max-width */
@media screen and (max-width: 750px) {
    /* Customizing feature component as per needs */
    .gf__whatgf-feature .gf__feature-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gf__whatgf-feature .gf__feature-container__feature-text {
        margin-top: 0.5rem;
    }

    .gf__whatgf-heading {
        margin: 1.5rem;
    }

    .gf__whatgf-heading h1 {
        font-size: 26px;
        line-height: 32px;
    }
}
/* For normal Smartphone */
@media screen and (max-width: 485px) {
    .gf__whatgf-heading {
        margin: 0.5rem;
    }
    
    .gf__whatgf-heading h1 {
        font-size: 22px;
        line-height: 32px;
    }

    .gf__whatgf-container {
        margin: 0.5rem 0;
    }

    /* Customizing feature component as per needs */
    .gf__whatgf-container .gf__feature-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}