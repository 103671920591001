.gf__download {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin: 4rem;
}

.gf__download img {
    width: 100%;
    max-width: 340px;

    cursor: pointer;
}


@media screen and (max-width: 1200px) { 
    .gf__download img {
        max-width: 260px;
    }
}