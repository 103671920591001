@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --font-family: 'Manrope', sans-serif;

    --gradient-text: linear-gradient(89.97deg, #AF64FF 1.84%, #F99D6C 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #AF64FF -13.86%, #F99D6C 99.55%);

    --gradient-sub-text: linear-gradient(90deg, #942FFF 0%, #1ED6FF 100%);

    --color-bg: #040C18;
    --color-footer: #031B34;
    --color-screen-bg: #042c54;
    --color-text: #9FC7F0;
    --color-subtext: #FF8C73;
    --color-linktext: #469BFF;
}
