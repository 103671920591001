.gf__features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: normal;

    width: 100%;
}

.gf__features-heading {
    flex: 1;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: normal;
}

.gf__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.gf__features-container {
    flex: 3.5;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: normal;
}

/* Media Query
 */
/* Screen Width Size가 더 큰 경우, 위에서 정의된 스타일 대로 표시된다. */

/* Screen Width Size가 650 ~ 990 까지..  */
@media screen and (max-width: 990px) {
    .gf__features {
        flex-direction: column;
    }

    .gf__features-heading {
        margin: 0 0 2rem 0;
    }
}
/* Screen Width Size가 ~ 650 까지..  */
@media screen and (max-width: 650px) {
    .gf__features-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

}