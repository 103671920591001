/* for all the elements */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

/* anchor tag : Text에 Underline 제거하고, 링크 색상을 적용하지 않게 */
a {
    color: unset;
    text-decoration: none;
}

/* Gradient Color.
   이 데이터는 https://angrytools.com/gradient/ 에서 만들어서 Copy해 온 데이터 임.  
   시작 색상 : #002853  
   끝 색상 : #040C18
   Radial
   X: 3%,  Y: 25%
 */
.gradient__bg {
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.gradient__sub-text {
    background: var(--gradient-sub-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.section__margin--card {
    margin: 6rem 4rem 4rem 4rem;
}


.top_section_sticky {
    position:sticky;
    top: 0;
    /* width: 100% */
    left: 0;
}

/* ----------------------------------- */
#cookie-consent-display-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem 8rem;
    background-color: #fff;
    transition: opacity 1s ease;

}

.cookie-consent-display-popup__hidden {
    opacity: 0;
}
/* ----------------------------------- */

/* CSS Animation.
   이 데이터는https://animista.net/play/basic/scale-up 에서 가져온 데이터 임. 
 */
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* Media Query
 */
 /* Screen Width Size가 700 보다 더큰 경우, 위에서 정의된 스타일 대로 표시된다. */

 @media screen and (max-width: 1200px) { 
     .section__padding {
         padding: 2rem 3rem;
     }

     .section__margin {
         margin: 4rem;
     }

     .section__margin--card {
        margin: 4rem 2rem 2rem 2rem;
    }
 }
 /* Screen Width Size가 ~ 550 까지..  Smart-phones */
 @media screen and (max-width: 550px) { 
     .section__padding {
         padding: 2rem;
     }

     .section__margin {
         margin: 4rem 2rem;
     }
 }