.gf__navbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: normal;

    padding: 2rem 6rem;
}

.gf__navbar-links {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
}

.gf__navbar-links_logo img {
    width: 100%;
    max-width: 266px;
}

.gf__navbar-links_container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    
    padding: 0 3rem 0 1.5rem;
}

.gf__navbar-links_container p,
.gpt3__navbar-language p,
.gf__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gf__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.gf__navbar-menu {
    margin-left: 3rem;

    display: none;
    position: relative;
}

.gf__navbar-menu svg {
    cursor: pointer;
}

.gf__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

    text-align: start;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 260px;
    border-radius: 5px;

    box-sizing: border-box;
    background: #042C54;
    border: 3px solid #042C54;
    box-shadow: 3px 3px 4px rgba(103, 250, 250, 0.36);
}

.gpt3__navbar-language {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;

    padding: 1rem 0;
}

/* Media Query
 */
/* Screen Width Size가 더 큰 경우, 위에서 정의된 스타일 대로 표시된다. */

@media screen and (max-width: 1500px) { 
    .gf__navbar-links_container p,
    .gpt3__navbar-language p {
        font-size: 18px;
        line-height: 22px;
    }
}
@media screen and (max-width: 1200px) { 
    .gf__navbar {
        padding: 2rem 4rem;
    }
    
    .gf__navbar-links_container p,
    .gpt3__navbar-language p {
        font-size: 16px;
        line-height: 20px;
    }

    .gf__navbar-links_logo img {
        max-width: 200px;
    }
}

/* Screen Width Size가 ~ 1050 까지..  Large Size Devices like big tablets */
@media screen and (max-width: 1050px) { 
    .gf__navbar-links_container {
        display: none;
    }

    .gf__navbar-menu {
        display: flex;
    }

    .gf__navbar-menu_container p {
        font-size: 20px;
        line-height: 22px;

        margin: 2.5rem 0;
    }
}
/* Low Resolution Tablets and ipads max-width */
@media screen and (max-width: 780px) { 
    .gf__navbar {
        padding: 1rem 4rem;
    }

    .gf__navbar-menu_container p {
        font-size: 18px;
        line-height: 20px;

        margin: 2.5rem 0;
    }
}
/* Screen Width Size가 ~ 550 까지..  Smart-phones */
@media screen and (max-width: 550px) { 
    .gf__navbar {
        padding: 2rem;
    }

    .gf__navbar-menu_container {
        top: 20px;
    }
}

/* For normal Smartphone */
@media screen and (max-width: 485px) { 
    .gf__navbar {
        padding: 0.8rem 2rem;
    }

    .gf__navbar-menu:last-of-type {
        margin-left: auto;
    }
}