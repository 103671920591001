.gf__document {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: normal;

    width: 100%;
}

.gf__document__title h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 40px;
    font-weight: 800;
    letter-spacing: 0.04em;
    color: var(--color-subtext);
    margin-bottom: 1.5rem;
}

.gf__document__text p {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: var(--color-text);
    margin-bottom: 0.8rem;
}

/* Media Query
 */
 /* Screen Width Size가 더 큰 경우, 위에서 정의된 스타일 대로 표시된다. */

 /* Screen Width Size가 ~ 650 까지..  */
 @media screen and (max-width: 650px) {
    .gf__document__title h3 {
        font-size: 18px;
        line-height: 34px;
    }

    .gf__document__text p {
        font-size: 16px;
        line-height: 20px;
    }
}