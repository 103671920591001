.gf__feature-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    padding: 1rem;
}

.gf__feature-container__feature-title {
    flex: 1;
    max-width: 500px;
    margin: 1rem 2rem 1rem 0;
}

.gf__feature-container__feature-title h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 40px;
    font-weight: 800;
    letter-spacing: 0.04em;

    color: var(--color-subtext);
    /* color: #fff; */
}

/* accent line */
.gf__feature-container__feature-title div {
    width: 58px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.5rem;
}

.gf__feature-container__feature-text {
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
}

.gf__feature-container__feature-text p {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: var(--color-text);
    margin-bottom: 0.8rem;
}


/* Media Query
 */
 /* Screen Width Size가 더 큰 경우, 위에서 정의된 스타일 대로 표시된다. */

 /* Screen Width Size가 ~ 650 까지..  */
@media screen and (max-width: 650px) {
    .gf__feature-container__feature-title h3 {
        font-size: 18px;
        line-height: 34px;
    }

    .gf__feature-container__feature-text p {
        font-size: 16px;
        line-height: 20px;
    }
}

/* For normal Smartphone */
@media screen and (max-width: 485px) {

}