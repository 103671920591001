.gf__popup {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    width: 100%;
    max-width: 98%;
    padding: 1rem 4rem;

    position: fixed;
    bottom: 1%;
    left: 1%;
    right: 1%;
    background-color: #fff;
    /* box-shadow: 0 0 18 0 rgba(0, 0, 0, 0.75); */
}

.overlay-on-screen {
    background-color: rgba(0, 0, 0, 0.4);

    position: fixed;
    width: 100%;
    height: 100%;
}

.gf__popup-close-button {
    position: absolute;
	right: 0;
    margin: 0 1rem;
    cursor: pointer;
}

.gf__popup-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gf__popup-container__notice {
    flex: 1;
    width: 100%;
}

.gf__popup-container__notice h4 {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 26px;
    font-weight: 800;
    letter-spacing: 0.04em;
}

.gf__popup-container__notice p {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
}

.gf__popup-container__buttons {
    font-family: var(--font-family);

    margin: 0 4rem;
}

.btn-xl {
    padding: 5px 20px;  /* original was 10px 20px */ 
    font-size: 20px;
    border-radius: 10px;
    width:200%;   /* original was 50% */ 
}

.gf__link-text {
    font-family: var(--font-family);
    font-size: 15px;
    line-height: 18px;
    font-weight: 800;
    color: var(--color-linktext);
}
/* Media Query
 */
 /* Screen Width Size가 더 큰 경우, 위에서 정의된 스타일 대로 표시된다. */

 @media screen and (max-width: 1700px) {
    .btn-xl {
        width:100%;    
    }
}

@media screen and (max-width: 1200px) {
    .gf__popup {
        bottom: 0.5%;
    }
}

@media screen and (max-width: 1200px) {
    .gf__popup-container__buttons {
        margin: 0 2rem;
    }
}

@media screen and (max-width: 700px) {
    .gf__popup {
        padding: 0.5rem 2rem;
    }

    .gf__popup-container__buttons {
        margin: 0 0 0 1rem;
    }
}